<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'云端记录'" :total="queryData.total" />

            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="搜索">
                    <el-input placeholder="任务id" v-model="keyword"></el-input>
                </el-form-item>

                <el-form-item label="任务状态">
                    <el-select
                        v-model="queryData.task_status"
                        placeholder="用户状态"
                    >
                        <el-option label="全部" :value="null"></el-option>
                        <el-option label="进行中" :value="0"></el-option>
                        <el-option label="执行完成" :value="1"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item style="float: right">
                    <el-button type="primary" @click="getList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-row :gutter="20">
            <el-col :span="18">
                <div class="layoutTabaleBorder">
                    <el-table
                        :data="queryData.tableData"
                        style="width: 100%"
                        @row-click="toTaskInfo"
                    >
                        <el-table-column prop="garage_id" label="车库id">
                        </el-table-column>

                        <el-table-column
                            prop="create_time"
                            label="任务创造时间"
                        >
                        </el-table-column>

                        <el-table-column prop="license_plate" label="车牌号">
                        </el-table-column>
                        <el-table-column prop="re_send_count" label="下发次数">
                        </el-table-column>
                        <el-table-column prop="task_status" label="任务状态">
                        </el-table-column>
                        <el-table-column prop="execution_mode" label="执行方式">
                        </el-table-column>
                        <el-table-column prop="task_type" label="任务类型">
                        </el-table-column>
                        <el-table-column prop="revc_status" label="接收状态">
                        </el-table-column>
                        <el-table-column prop="revc_time" label="接收时间">
                        </el-table-column>

                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-dropdown type="primary">
                                    <el-button type="primary">
                                        更多<i
                                            class="el-icon-arrow-down el-icon--right"
                                        ></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            v-for="item in tableDropdown"
                                            :key="item.name"
                                            @click.native="
                                                handle(scope.row, item.name)
                                            "
                                            >{{ item.name }}</el-dropdown-item
                                        >
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>

            <el-col :span="6" class="side-info" v-if="taskInfoList">
                <el-descriptions
                    title="消息任务详细信息:"
                    direction="vertical"
                    :column="4"
                    size="small "
                    class="side-info-item"
                    v-for="(task,index) in taskInfoList" :key="index"
                    border
                >
                    <el-descriptions-item label="KAFKA消息：" :span="4"
                        >{{task.kafka_data}}</el-descriptions-item
                    >

                    <el-descriptions-item label="创建时间："
                        >{{task.create_time}}</el-descriptions-item
                    >
                    <el-descriptions-item label="任务ID："
                        >{{task.task_id}}</el-descriptions-item
                    >
                    <el-descriptions-item label="执行类型："
                        >{{task.type_str}}</el-descriptions-item
                    >
                    <el-descriptions-item label="执行结果："
                        >{{task.status_str}}
                    </el-descriptions-item>
                    <el-descriptions-item label="TCP包头：" :span="4"
                        >{{task.headerbag}}</el-descriptions-item
                    >
                    <el-descriptions-item label="错误：" :span="4"
                        >{{task.error? task.error : '未发生错误'}}</el-descriptions-item
                    >
                </el-descriptions>
            </el-col>
            <el-col :span="6" class="side-info" v-else>
                <div class="task-info" >
                    <div class="info-header">
                        <div class="info-header__title">消息任务详细信息：</div>
                    </div>
                    <div class="info-body">
                        <div class="info-body__content">
                            {{ taskInfoHint }}
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <div class="layoutBorder">
            <Pagination
                :total="queryData.total"
                :page.sync="queryData.currentPage"
                @pagination="getList()"
                :limit.sync="queryData.pageSize"
            />
        </div>
    </div>
</template>

<script>
import {
    task_get,
    task_info,
    task_update
} from '@/api/manage/operation/taskmanage/task.js';

import Pagination from '@/components/Pagination.vue';

export default {
    name: 'start',
    data() {
        return {
            keyword: null,
            queryData: {
                task_status: 0,
                tableData: [],
                currentPage: 1,
                total: 0,
                pageCount: 0,
                pageSize: 10
            },
            tableDropdown: [
                {
                    id: '0',
                    name: '全部'
                },
                {
                    id: '1',
                    name: '未接收'
                },
                {
                    id: '2',
                    name: '已经接收'
                }
            ],
            taskInfoHint:'请点击左侧列表内任务，查看任务详情',
            taskInfoList: undefined
        };
    },
    components: {
        Pagination
    },
    created() {
        this.getList(); // 定义该函数在页面一加载时就执行
    },
    methods: {
        handle(row, methods) {
            // console.log(row, methods);
            if (
                methods == '全部' ||
                methods == '未接收' ||
                methods == '已经接收'
            ) {
                this.editstatus(row.task_id, methods);
            }
        },
        editstatus(task_id, revc_status) {
            if (revc_status == '全部') {
                revc_status = 0;
            } else if (revc_status == '未接收') {
                revc_status = 1;
            } else if (revc_status == '已经接收') {
                revc_status = 2;
            }
            let temp = {
                task_id: task_id,
                revc_status: revc_status
            };
            task_update(temp).then(res => {
                if (res.code == 200) {
                    this.$message.success('数据更改成功');
                    this.getList();
                } else {
                    this.$message.info('数据更改失败');
                    this.getList();
                }
            });
        },
        // 获取接口数据的方法
        getList() {
            task_get({
                querypage: this.queryData.currentPage,
                pagesize: this.queryData.pageSize,
                task_type: 0, //0全部, 1停车, 2取车
                revc_status: 0, //0全部 ,1未接收, 2已接收
                task_status: this.queryData.task_status //0全部 ,1进行中, 2执行完成, 3管理员手动完成
            }).then(res => {
                if (res.code == 200) {
                    this.queryData.total = res.data.total;
                    this.queryData.currentPage = res.data.currentPage;
                    this.queryData.pageCount = res.data.pageCount;
                    this.queryData.tableData = res.data.result;
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        duration: 5 * 1000
                    });
                }
            });
        },
        toTaskInfo(row) {
            console.log(row, '点击行数据');
            let ids = row.task_id;
            task_info({ task_id: ids }).then(res => {
                if(res.data.msglog == ""){
                    this.taskInfoHint = '任务暂无详情'
                }else{
                    this.taskInfoList = res.data.msglog;
                }
    
            });
        }
    }
};
</script>

<style type="scss">
.demo-table-expand {
    width: 90%;
    margin-left: 50px;
}
.side-info {
    height: 700px;
    display: flex;
    overflow: auto;
    padding: 10px;
    flex-direction: column;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    background-color: #f2f3f5;
}
.side-info-item {
    margin: 12px 0;
}
.info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.info-header__title {
    font-size: 16px;
    font-weight: 700;
}
.info-body {
    margin-top: 80px;
    text-align: center;
    color: gray;
    font-size: 16px;
    font-weight: 700;
}
</style>
